@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #FAF9F6;
}

